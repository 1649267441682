import { API } from "utils";

export const getAdBlockById = async (id) => {
  try {
    const response = await API().get(`api/adblocks/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const updateAdBlockById = async (id, data) => {
  try {
    const response = await API().put(`api/adblocks/${id}`, data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const deleteAdBlockById = async (id) => {
  try {
    const response = await API().delete(`api/adblocks/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getAdblockProducts = async (id, productId) => {
  try {
    const response = await API().get(
      `api/adblocks/${id}/products/${productId}`
    );
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
export const getAdblockOffers = async (id, productId) => {
  try {
    const response = await API().get(`api/adblocks/${id}/offers/${productId}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
export const deleteAdblockOffers = async (id, offerId) => {
  try {
    const response = await API().delete(`api/adblocks/${id}/offers/${offerId}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const deleteAdblockProducts = async (id, productId) => {
  try {
    const response = await API().delete(
      `api/adblocks/${id}/product/${productId}`
    );
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const addAdblockProduct = async (id, data = {}) => {
  try {
    const response = await API().post(`api/adblocks/${id}/add-product`, data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const addAdblockOffer = async (id, data = {}) => {
  try {
    const response = await API().post(`api/adblocks/${id}/add-offer`, data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const updateAdblockOffer = async (adblockId, offerId, data = {}) => {
  try {
    const response = await API().put(
      `api/adblocks/${adblockId}/offers/${offerId}/update-status`,
      data
    );
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const approveOffer = async (adblockId, data = {}) => {
  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).post(
      `api/adblocks/${adblockId}/approve-offers`,
      data
    );
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const unApproveOffer = async (adblockId) => {
  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).post(
      `api/adblocks/${adblockId}/unapprove-offers`,
    );
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const approveProduct = async (adblockId, data = {}) => {
  try {
    const response = await API({}, {}, { timeout: Number.MAX_SAFE_INTEGER, retries: 0 }).post(
      `api/adblocks/${adblockId}/approve-products`,
      data
    );
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const rejectOffers = async (adblockId, data = {}) => {
  try {
    const response = await API().post(
      `api/adblocks/${adblockId}/reject-offers`,
      data
    );
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const rejectProducts = async (adblockId, data = {}) => {
  try {
    const response = await API().post(
      `api/adblocks/${adblockId}/reject-products`,
      data
    );
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const skipAdblock = async (adblockId, data = {}, screen = "") => {
  try {
    const response = await API().post(
      `api/adblocks/${adblockId}/skip?screen=${screen}`,
      data
    );
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const doNotEnterAdblock = async (adblockId, data = {}, screen = "") => {
  try {
    const response = await API().post(
      `api/adblocks/${adblockId}/do-not-enter?screen=${screen}`,
      data
    );
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const skipTopDownAdblock = async (adblockId, data, screen = "") => {
  try {
    const response = await API().post(
      `api/adblocks/${adblockId}/skip?screen=${screen}`,
      data
    );
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const doNotEnterTopDownAdblock = async (
  adblockId,
  data,
  screen = ""
) => {
  if (adblockId) {
    try {
      const response = await API().post(
        `api/adblocks/${adblockId}/do-not-enter?screen=${screen}`,
        data
      );
      return { success: true, payload: response.data };
    } catch (e) {
      return { success: false, payload: [], error: e };
    }
  }
};

export const getNextOffer = async () => {
  try {
    const response = await API().post(`api/adblocks/next-offer`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getNextProduct = async () => {
  try {
    const response = await API().post(`api/adblocks/next-product`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const setAdblockEvents = async (id, events = []) => {
  if (id) {
    try {
      const response = await API().post(`api/pages/${id}/set-adblock-events`, {
        events: events.join(","),
      });
      return { success: true, payload: response.data };
    } catch (e) {
      return { success: false, payload: [], error: e };
    }
  }
};

export const deleteProduct = async ({ adblockId, productId }) => {
  if (adblockId) {
    try {
      const response = await API().delete(
        `api/adblocks/${adblockId}/product/${productId}`
      );
      return { success: true, payload: response.data };
    } catch (e) {
      return { success: false, payload: [], error: e };
    }
  }
};

export const deleteOffer = async ({ adblockId, offerId }) => {
  if (adblockId) {
    try {
      const response = await API().delete(
        `api/adblocks/${adblockId}/offers/${offerId}`
      );
      return { success: true, payload: response.data };
    } catch (e) {
      return { success: false, payload: [], error: e };
    }
  }
};

export const resetTimerAdblock = async (adblockId, screen = "product") => {
  if (adblockId) {
    try {
      const response = await API().post(
        `api/adblocks/${adblockId}/reset-timer?screen=${screen}`
      );
      return { success: true, payload: response.data };
    } catch (e) {
      return { success: false, payload: [], error: e };
    }
  }
};

export const rejectAdblock = async (adblockId, screen = "product", rejected_message = '', remove_children = false) => {
  if (adblockId) {
    try {
      const response = await API().post(
        `api/adblocks/${adblockId}/reject?screen=${screen}&reject_message=${rejected_message}&remove_children=${remove_children}`,
        {}
      );
      return { success: true, payload: response.data };
    } catch (e) {
      return { success: false, payload: [], error: e };
    }
  }
};

export const resetAdblock = async (adblockId) => {
  if (adblockId) {
    try {
      const response = await API().post(
        `api/adblocks/${adblockId}/reset`
      );
      return { success: true, payload: response.data };
    } catch (e) {
      return { success: false, payload: [], error: e };
    }
  }
};

export const copyParentProductsToChildren = async (adblockId) => {
  if (adblockId) {
    try {
      const response = await API().post(
        `api/adblocks/${adblockId}/id-copy-products-to-children`
      );
      return { success: true, payload: response.data };
    } catch (e) {
      return { success: false, payload: [], error: e };
    }
  }
};

export const copyParentOffersToChildren = async (adblockId) => {
  if (adblockId) {
    try {
      const response = await API().post(
        `api/adblocks/${adblockId}/id-copy-offers-to-children`
      );
      return { success: true, payload: response.data };
    } catch (e) {
      return { success: false, payload: [], error: e };
    }
  }
};

export const copyAllData = async (id) => {

  let copyPaths = [
    `api/adblocks/${id}/id-copy-products-to-children`,
    `api/adblocks/${id}/id-copy-offers-to-children`
  ];

  try {

    const response = await Promise.all(copyPaths.map((endpoint) => API().post(endpoint)));
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

// TODO to make things more faster if stuff slows down again
export const getSimilarAdBlockProducts = async (adblockId) => {
  if (adblockId) {
    try {
      const response = await API().get(
        `api/adblocks/${adblockId}/existing-products`
      );
      return { success: true, payload: response.data };
    } catch (e) {
      return { success: false, payload: [], error: e };
    }
  }
};

export const getSimilarAdBlockOffers = async (adblockId) => {
  if (adblockId) {
    try {
      const response = await API().get(
        `api/adblocks/${adblockId}/existing-offers`
      );
      return { success: true, payload: response.data };
    } catch (e) {
      return { success: false, payload: [], error: e };
    }
  }
};

export const setDupe = async (adblockId, parentId, startTime = '') => {
  if (adblockId && parentId) {
    try {
      let data = {}
      if (startTime) {
        data = {
          uuid: adblockId,
          dupe_of: parentId,
          start_time: startTime
        }
      } else {
        data = {
          uuid: adblockId,
          dupe_of: parentId
        }
      }
      const response = await API().post(`api/adblocks/${adblockId}/set-dupe`, data)
      return { success: true, payload: response.data };
    } catch (e) {
      return { success: false, payload: [], error: e };
    }
  }
}

export const importProducts = async (adblockId, parentId, startTime = '') => {
  if (adblockId && parentId) {
    try {
      let data = {}
      if (startTime) {
        data = {
          uuid: adblockId,
          dupe_of: parentId,
          start_time: startTime
        }
      } else {
        data = {
          uuid: adblockId,
          dupe_of: parentId,
        }
      }
      const response = await API().post(`api/adblocks/${adblockId}/import_products`, data)
      return { success: true, payload: response.data };
    } catch (e) {
      return { success: false, payload: [], error: e };
    }
  }
}

export const importOffers = async (adblockId, parentId, startTime = '') => {
  if (adblockId && parentId) {
    try {
      let data = {}
      if (startTime) {
        data = {
          uuid: adblockId,
          dupe_of: parentId,
          start_time: startTime
        }
      } else {
        data = {
          uuid: adblockId,
          dupe_of: parentId,
        }
      }
      const response = await API().post(`api/adblocks/${adblockId}/import_offers`, data)
      return { success: true, payload: response.data };
    } catch (e) {
      return { success: false, payload: [], error: e };
    }
  }
}

export const fetchImageMatch = async (adblockId) => {
  if (adblockId) {
    try {
      const response = await API().get(
        `api/adblocks/${adblockId}/image-match`
      );
      return { success: true, payload: response.data };
    } catch (e) {
      return { success: false, payload: [], error: e };
    }
  }
}

export const dupeProduct = async (adblockId, parentId, startTime = '') => {
  if (adblockId) {
    try {
      const response = await API().post(`api/adblocks/${adblockId}/dupe_products`, {
        uuid: adblockId,
        dupe_of: parentId,
        start_time: startTime
      });
      return { success: true, payload: response.data };
    } catch (e) {
      return { success: false, payload: [], error: e };
    }
  }
}

export const dupeOffer = async (adblockId, parentId, startTime = '') => {
  if (adblockId) {
    try {
      const response = await API().post(`api/adblocks/${adblockId}/dupe_offers`, {
        uuid: adblockId,
        dupe_of: parentId,
        start_time: startTime
      });
      return { success: true, payload: response.data };
    } catch (e) {
      return { success: false, payload: [], error: e };
    }
  }
}
