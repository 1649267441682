/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Modal, Button } from "components";
import { saveCircularIncentives } from "api";
import { useMainContext } from "contexts";
import { validateIncentive } from "utils";
import styled from "styled-components";

export const DeleteCircularIncentiveModal = ({
  open,
  onClose,
  onDelete = () => {},
  text = "",
  incentiveData = [],
  data,
}) => {
  const { currentCircularId } = useMainContext();
  const [isLoading, setIsLoading] = useState(false);
  const handleCancel = () => {
    onClose();
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    await saveCircularIncentives(
      currentCircularId,
      incentiveData.filter(
        (a) =>
          a?.index !== data.index &&
          !a?.isClonedIncentive &&
          !a?.isAddedIncentive &&
          validateIncentive(a) &&
          a?.incentive_attach_to === "Circular"
      )
    );
    setIsLoading(false);
    onDelete();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="medium"
      title={"Delete Circular Incentive"}
    >
      <div>
        <div>
          Deleting a Circular Incentive will remove them form all associated
          adblocks. <br /> Are you use your want to delete {text}?
        </div>
        <WarningMessage><WarningText>WARNING:</WarningText> You have to approve the block again.</WarningMessage>
      </div>
      <div className="d-flex mt-4">
        <Button
          size="small"
          buttonTheme="dark"
          width="fit-content"
          className="ml-auto"
          type="button"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          size="small"
          buttonTheme="primary"
          width="fit-content"
          className="ml-4"
          type="button"
          onClick={(e) => handleDelete(e)}
          isLoading={isLoading}
        >
          Delete
        </Button>
      </div>
    </Modal>
  );
};

const WarningMessage = styled.div`
    font-size:14px;
    padding:10px;
`;

const WarningText = styled.span`
  color:${(props) => props.theme.palette.warning};
`;
