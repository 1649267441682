/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Modal, Button } from "components";
import { deleteOffer, getPageAdblocks } from "api";
import { useMutation } from "react-query";
import styled from "styled-components";

export const OfferDeletionModal = ({
  open,
  onClose,
  onDelete = () => {},
  adblockId,
  pageId,
  deletingOffer,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const deleteMuation = useMutation(deleteOffer);

  const handleCancel = () => {
    onClose();
  };

  const handleDelete = async (e) => {
    setIsLoading(true);
    e.stopPropagation();
    await deleteMuation.mutateAsync({
      adblockId,
      offerId: deletingOffer?.uuid,
    }, {
      onSuccess: () => {
        setIsLoading(false);
        onDelete(deletingOffer?.uuid);
        onClose();
      }
    });
    
  };

  return (
    <Modal open={open} onClose={onClose} size="medium" title={"Offer Deletion"}>
      <div>
        <div>Are you sure you want to delete {deletingOffer?.offer_text}?</div>
        <WarningMessage><WarningText>WARNING:</WarningText> You have to approve the block again.</WarningMessage>
          
      </div>
      <div className="d-flex mt-4">
        <Button
          size="small"
          buttonTheme="dark"
          width="fit-content"
          className="ml-auto"
          type="button"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          size="small"
          buttonTheme="error"
          width="fit-content"
          className="ml-4"
          type="button"
          isLoading={isLoading}
          onClick={(e) => handleDelete(e)}
        >
          Delete
        </Button>
      </div>
    </Modal>
  );
};

const WarningMessage = styled.div`
    font-size:14px;
    padding:10px;
`;

const WarningText = styled.span`
  color:${(props) => props.theme.palette.warning};
`;